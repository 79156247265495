import axios from 'axios'

export const getModules = async () => {
    const courseRes = await axios.get("/api/coursework/modules");
    //console.log('courseRes:', courseRes)

    if ( courseRes.data.error )
    {
        return console.log('getModules error:', courseRes.data.error)
    }

    return courseRes
}

export const getModulesInfo = async () => {
    const modInfoRes = await axios.get('/api/coursework/getModulesInfo')
    //console.log('moduleInfoRes:', modInfoRes)

    if ( modInfoRes.data.error )
    {
        return console.log('getModulesInfo error:', modInfoRes.data.error)
    }

    return modInfoRes
}