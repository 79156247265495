import React, { useEffect, createContext, useContext, useReducer } from 'react'
import { useAuth } from './AuthProvider'
import { getUserProgress } from '../services/userService'
import { getModulesInfo } from '../services/courseService'

const ProgressContext = createContext();
export const useProgress = () => {
    return useContext(ProgressContext)
}

export const ProgressProvider = ({children}) => {
    // used so we don't make a backend call for unlogged in user
    const { user } = useAuth()

    //As soon as the component loads get the Course from the backend
    useEffect(() => {

        // Don't check for progress if user isn't logged in
        if ( !user.loggedIn )
        {
            return
        }

        const getUserProgressFromBackend = async () => {
            const userProgRes = await getUserProgress()
            const modulesInfoRes = await getModulesInfo()

            //console.log('userProgRes:', userProgRes)
            //console.log('modulesInfoRes:', modulesInfoRes)

            if ( userProgRes && modulesInfoRes )
            {
                // check if modules were updated in backend by comparing slidesLength array length, meaning check if module counts match
                if ( modulesInfoRes.data.slideLengths.length === userProgRes.data.moduleCompletion.length )
                {
                    setModuleCompletion(userProgRes.data.moduleCompletion)
                }
                // counts don't match, we must init a new module completion array with newly added modules
                else
                {
                    // start at old module list length
                    // add new completion values for new modules
                    let newModuleCompletion = [...userProgRes.data.moduleCompletion]
                    for ( let i = userProgRes.data.moduleCompletion.length; i < modulesInfoRes.data.slideLengths.length; ++i )
                    {
                        newModuleCompletion[i] = -1
                    }

                    setModuleCompletion(newModuleCompletion)
                }

                setLastLesson({...(userProgRes.data.lastSaved)})
            }
        }
        /*
        On user dashboard load, the progress provider will load
            in useEffect, check for any localStorage progress before getting from backend
            (this is for when user refreshes, it should keep localStorage;
            exiting properly through clicking navbar or finishing module should clear it)
         */
        const storedLastLesson = JSON.parse(localStorage.getItem('lastLesson'));
        const storedCompletion = JSON.parse(localStorage.getItem('moduleCompletion'))
        if ( storedLastLesson && storedCompletion)
        {
            //console.log('Found stored lastLesson and moduleCompletion!')
            
            //console.log('localStorage lastLesson:', storedLastLesson)
            setLastLesson(storedLastLesson);

            //console.log("localStorage moduleCompletion:", storedCompletion);
            setModuleCompletion(storedCompletion);
        }
        else
        {
            //  if no local storage data, get backend progress
            getUserProgressFromBackend()
        }

        //console.log('Progress Provider useEffect finished executing!')
    }, [user.loggedIn])

    const initialState = {
        // used to tell if we should automatically render into presentor and last slide
        lastSaved: {
            module: 0,
            slide: 0
        },
        moduleCompletion: [-1]
    }

    const [progress, dispatch] = useReducer(ProgressReducer, initialState)

    //Dispatch to save last lesson data and store in local storage to prevent loss on browser reload
    const setLastLesson = ({module, slide}) => {
        //console.log("setting LastLesson :", {module, slide});
        dispatch({
            type: 'SET_LAST_LESSON',
            payload: { module, slide }
        });
        localStorage.setItem('lastLesson', JSON.stringify({module, slide}));
    }

    //Dispatch to save modules completed data and store in local storage to prevent loss on browser reload
    const setModuleCompletion = (moduleCompletion) => {
        //console.log("setting ModuleCompletion:", moduleCompletion);
        dispatch({
            type: 'SET_MODULE_COMPLETION',
            payload: moduleCompletion

        });
        localStorage.setItem('moduleCompletion', JSON.stringify(moduleCompletion))
    }

    return(
        <ProgressContext.Provider value={{
            progress,
            setLastLesson,
            setModuleCompletion,
        }}>
            {children}
        </ProgressContext.Provider>
    )
}

const ProgressReducer = (state, action) => {
    switch(action.type) 
    {
        case 'SET_LAST_LESSON':
            return {
                ...state,
                lastSaved: { 
                    module: action.payload.module, 
                    slide: action.payload.slide
                } 
            }
        case 'SET_MODULE_COMPLETION':
            return {
                ...state,
                moduleCompletion: action.payload
            }
        default:
            return state;
    }
}