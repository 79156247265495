import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import UserStatistics from './components/UserStatistics/UserStatistics';
import ProfileBar from './components/layout/ProfileBar';
import ContactUs from "./components/contactus/ContactUs";
import AboutUs from "./components/AboutUs/AboutUs";
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import UserDashboard from './components/UserDashboard/UserDashboard';
import FinishedModule from './components/finishedmodule/FinishedModule'
import PageNotFound from './components/PageNotFound404';
import PrivateRoute from './components/routing/PrivateRoute';
import Presentor from './components/presentor/Presentor';
import Profile from './components/Profile/Profile';
import TrainingVideos from './components/TrainingVideos/TrainingVideos';
import TrainingVideoView from './components/TrainingVideoView/TrainingVideoView';

import { AuthProvider } from './context/AuthProvider';
import { ProgressProvider } from './context/ProgressProvider';

import './App.css';

const App = () => {
  return (
      <Router>
        <div className='main-grid-layout'>
          <AuthProvider>
            <ProfileBar />
            <Navbar />
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/register" component={Register} /> 
              <Route exact path="/login" component={Login} /> 
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path='/aboutus' component={AboutUs} />
              <Route exact path='/traininginfo' component={TrainingVideos} />
              <Route exact path='/traininginfo/:trainingVideoId' component={TrainingVideoView} />

              <Route exact path="/forgotpassword" component={ForgotPassword}/>
              <Route exact path="/resetpassword/:resetToken" component={ResetPassword}/>

              <ProgressProvider>
                <PrivateRoute path="/user/dashboard" component={UserDashboard}/>
                <PrivateRoute path="/users/statistics" component={UserStatistics} />  
                <PrivateRoute path="/presentor" component={Presentor} />
                <PrivateRoute path="/finishedmodule" component={FinishedModule}/>
	            	<PrivateRoute path='/profile' component={Profile} />
              </ProgressProvider>

              <Route path="*" component={PageNotFound}/>
            </Switch>
          </AuthProvider>
      </div>
    </Router>
  );
}

export default App;