import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import { getUserStatistics } from '../../services/userService'
import { getModulesInfo } from '../../services/courseService'
import './UserStatistics.css'

const UserStatistics = () => {
	const { user } = useAuth()
	let history = useHistory()

	useEffect(() => {
		if ( user.role === 'user' )
		{
			history.push('/')
		}

		const getModulesInfoFromBackend = async () => {
			const modInfoRes = await getModulesInfo()
			if ( modInfoRes )
			{
				setModulesMetadata(modInfoRes.data)
				localStorage.setItem('modulesInfo', JSON.stringify(modInfoRes.data))
			}
		}

		const getUserStatsFromBackend = async () => {
			// if user role is set to admin, tell getUserStatistics that we are requesting all users as admin,
			// backend will check with DB role to verify that this call is telling the truth
			let userStatRes = null
			if ( user.role === 'admin' )
			{
				userStatRes = await getUserStatistics(true)
			}
			else
			{
				userStatRes = await getUserStatistics(false)
			}

			if ( userStatRes )
			{
				setUsers(userStatRes.data)
				localStorage.setItem('userStatistics', JSON.stringify(userStatRes.data))
			}
		}

		// save backend queries if localStorage data already exists
		// admin/instructor will have to logout and login to see updated data since live data is not a priority
		const localModuleInfo = JSON.parse(localStorage.getItem('modulesInfo'))
		const localUserStats = JSON.parse(localStorage.getItem('userStatistics'))
		if (localModuleInfo && localUserStats) 
		{
			setModulesMetadata(localModuleInfo)
			setUsers(localUserStats)
		}
		else
		{
			getModulesInfoFromBackend()
			getUserStatsFromBackend()
		}
	}, [])

	/*
	const testUsers = [
		{_id: 0, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 2, 5, 6, 7]}},
		{_id: 1, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 2, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 3, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 4, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 5, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 6, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 7, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 8, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 9, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 10, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [5, 5, 1, 0, 1]}},
		{_id: 11, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [6, -1, -1, -1, 4]}},
		{_id: 12, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [1, -1, -1, -1, 5]}},
		{_id: 13, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [2, -1, -1, -1, 6]}},
		{_id: 14, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [6, 8, 8, 8, 8]}},
		{_id: 15, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [7, 8, 8, 8, 8]}},
		{_id: 16, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [1, 8, 8, 8, 8]}},
		{_id: 17, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [2, 8, 8, 8, 8]}},
		{_id: 18, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [3, 8, 8, 8, 8]}},
		{_id: 19, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 20, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [8, 8, 8, 8, 8]}},
		{_id: 21, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [4, 8, 8, 8, 8]}},
		{_id: 22, firstName: 'Jon', lastName:'Doe', email: 'jondoe@mail.com', progress: {moduleCompletion: [5, 8, 8, 8, 8]}},
	]
	const testMetadata = {
		modulesInfo: [
			{
				title: 'Assessment & CARE',
				moduleNumber: 0
			},
			{
				title: 'Sexual Harassment',
				moduleNumber: 1
			},
			{
				title: 'Sheltering',
				moduleNumber: 2
			},
			{
				title: 'Managing the Situation',
				moduleNumber: 3
			},
			{
				title: 'Following Protocol',
				moduleNumber: 4
			},
		],
		slideLengths: [9, 9, 9, 9, 9],
		totalSlides: 45
	}
	*/
	const [selectedUser, setSelectedUser] = useState(null)
	const [modulesMetadata, setModulesMetadata] = useState(null)
	const [users, setUsers] = useState(null)

	const onUserSelection = e => {
		const id = e.currentTarget.getAttribute('value')
		//console.log(id)
		setSelectedUser( users.find( u => u._id === id ) )
	}

	const getUserTotalProgress = (moduleCompletion) => {
		let completeSlidesSum = 0

		for ( let i = 0; i < moduleCompletion.length; ++i )
		{
			completeSlidesSum += moduleCompletion[i] + 1 // offset 1 since we start at -1
		}

		return completeSlidesSum
	}

	const showUserModuleProgress = () => {
		if ( selectedUser )
		{
			return (
			<>
				<div className='selectedUserInfo'>
					<div>Name: {`${selectedUser.firstName} ${selectedUser.lastName}` || ''}</div>
					<div>Email: {selectedUser.email || ''}</div>
					{user.role === 'admin' ? <div>Role: {selectedUser.role.charAt(0).toUpperCase()+selectedUser.role.slice(1)}</div> : ''}
				</div>
				<div className='selectedUserProgress'>
					{modulesMetadata.modulesInfo.map(m => {
						let percentageCompleted = ((selectedUser.progress.moduleCompletion[m.moduleNumber]+1) / modulesMetadata.slideLengths[m.moduleNumber])*100
						return (
							<div className='moduleProgress' key={m.moduleNumber}>
								<div className='moduleHeader'>- Module {m.moduleNumber+1}: {m.title}</div>
								<div className='moduleProgressRow'>
									<div className='moduleProgressBar'>
										<span style={{width: `${percentageCompleted}%`}}></span>
									</div>
									<div style={{gridColumn: '2/3', paddingLeft: '5px', textAlign: 'center'}}>{`${percentageCompleted.toFixed(0)}%`}</div>
								</div>
							</div>
						)
					})}
				</div>
			</>
			)
		}
		else
		{
			return null
		}
	}

	if (users === null || modulesMetadata === null )
	{
		return <h1 className='loading-grid-layout'>LOADING USER STATISTICS...</h1>	
	}
	else
	{
		return (
		<div className='user-statistics-grid-layout' style={{overflow: 'hidden'}}>
			<div className='usersListDashboard'>
				<h2 style={{borderRadius: '3px', color: 'white', gridRow: '1/2', gridColumn: '1/2', backgroundColor: 'var(--primary-color)', textAlign: 'center'}}>Users</h2>
				<h2 style={{borderRadius: '3px', color: 'white', gridRow: '1/2', gridColumn: '2/3', backgroundColor: 'var(--primary-color)', textAlign: 'center'}}>User's Total Progression</h2>
				<ul className='usersList'>
					{users.map(u => 
						<div key={u._id} className={(selectedUser && selectedUser._id === u._id) ? 'userRowSelected' : 'userRow'} value={u._id} onClick={(e) => onUserSelection(e)}>
							<div className='userNames'>{`${user.role === 'admin' && u.role !== 'user' ? `${u.role.charAt(0).toUpperCase()+u.role.slice(1)}:` : ''} ${u.firstName} ${u.lastName}`}</div>
							<div className='totalProgressBar'>
								<span style={{width: `${ (getUserTotalProgress(u.progress.moduleCompletion) / modulesMetadata.totalSlides)*100 }%`}}>
								</span>							
							</div>
							<div style={{display: 'grid', textAlign: 'center'}}>{ `${ ((getUserTotalProgress(u.progress.moduleCompletion) / modulesMetadata.totalSlides)*100).toFixed(0) }%` }</div>
						</div>)} 
				</ul>
			</div>

			<div className='userDisplayDashboard'>
				<h2 
					style={{
						display: 'grid',
						gridRow: '1/2', 
						color: 'white', 
						borderRadius: '3px', 
						backgroundColor: 'var(--primary-color)', 
						textAlign: 'center',
					}}
				>
					{selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}'s Progress` : 'Select a user to display'}
				</h2>
				<div className='userModuleProgress'>
					{showUserModuleProgress()}
				</div>
			</div>
		</div>
		)
	}
}

export default UserStatistics;
