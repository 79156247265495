const PageNotFound = () => {
    return(
        <div className='404-Page-grid-layout' style={{margin: '50px'}}>
            <h1>Page Not Found</h1>
            <br/>
            <h3 style={{color: 'red'}}>Error: 404 - page doesn't exist</h3>
        </div>
    );
}

export default PageNotFound;