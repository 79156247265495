import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider'
import React, { useState, useEffect } from 'react'

const Navbar = () => {
    const [ links, setLinks ] = useState([{name: 'Home', to: '/'}])

    // on initial Navbar component load, determine what location we're at and set links accordingly
    const location = useLocation()

    // get user logged in state from auth context to aid in link building
    const { user } = useAuth()

    useEffect(() => {
        setLinks([{name: 'Home', to: '/'}])

        // on link or user change, reset links and title, then determine them again
        // must be set in this specific order as React batches state changes, but keeps order
        // so we also must use previous state in setState functions
        determineLinks()
    }, [location.pathname, user.loggedIn])

    const determineLinks = () => {
        if ( location.pathname === '/users/statistics' || location.pathname === '/user/dashboard' || location.pathname === '/presentor' ) 
        {
            if ( user.role === 'admin' || user.role === 'instructor' )
            {
                setLinks(prev => [...prev, 
                    {name: 'User Statistics', to:'/users/statistics'},
                    {name: 'My Dashboard', to:'/user/dashboard'}
                ])
            }
            else
            {
                setLinks(prev => [...prev, 
                    {name: 'My Dashboard', to:'/user/dashboard'}
                ])
            }
        }
        else
        {
            if ( user.loggedIn )
            {
                setLinks(prev => [...prev,         
                    {name: 'About Us', to: '/aboutus'},
                    {name: 'My Dashboard', to:'/user/dashboard'},
                    {name: 'Contact', to: '/contactus'}
                ])
            }
            else
            {
                setLinks(prev => [...prev,         
                    {name: 'About Us', to: '/aboutus'},
                    {name: 'Training Videos', to: '/traininginfo'},
                    {name: 'Contact', to: '/contactus'}
                ])
            }
        }

        //console.log(`location.pathname === '/'`, location.pathname === '/')
        //console.log('location.pathname:', location.pathname)
    }

    // go through our link state and create a button for each one
    const showNavButtons = () => {
        return links.map(btn => <li key={btn.to}><Link to={btn.to}>{btn.name}</Link></li>)
    }

    return (
        <nav className="navbar">
            <ul>
                {showNavButtons()}
            </ul>
        </nav>
    )
}

export default Navbar
