import {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

/*This component is used to send an email to the users email account with a link that takes them to 
*the Resetpassword component/page to reset their password.
*/
const ForgotPasswordScreen = () => {

    //state functions to set state variables
    const [email, setEmail] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    //form submit handler used in to send email to node server for password retrieval
    const forgotPasswordHandler = async (Event) => {
        Event.preventDefault();

        try
        {
            //try to send email to node server via Axios
            const forgotPasswordRes = await axios.post("/api/auth/forgotpassword", {email});
            
            if ( forgotPasswordRes && !forgotPasswordRes.data.error )
            {
                setSuccessMessage(forgotPasswordRes.data.message);
            }
            else
            {
                //Set the error message to empty in 5 sec
                setTimeout(() => {
                    setErrorMessage("");
                },
                5000);

                //show error in the form if response exists, otherwise set generic error message
                if ( forgotPasswordRes )
                {
                    setErrorMessage(forgotPasswordRes.data.error);
                }
                else
                {
                    setErrorMessage("Failed to send reset email to user!");
                }
                
                //console.log(forgotPasswordRes.data.error)

                //clear the email text box
                setEmail(""); 
            }
        }
        catch (error)
        {
            //Set the error message to empty in 5 sec
            setTimeout(() => {
                setErrorMessage("");
            },
            5000);
            setErrorMessage(error.response.data.error);
            //clear the email text box
            setEmail(""); 
        }
    }


    return(
        <div className='forgot-password-grid-layout'>
            <form className ='form' onSubmit={forgotPasswordHandler}>
                <h1 className='large'>Forgot your Password</h1>
                <p>Enter the email associated with your account. A link to reset your password will be sent to your email.</p>
                <div className='form-group'>
                    <label htmlFor="email">Email:</label>
                    <input 
                        type="text" 
                        required id="email" 
                        placeholder="Enter Email" 
                        value={email} 
                        onChange={(Event) => setEmail(Event.target.value)} />  
                </div>

                <div className='form-group'>     
                    <button type="submit" style={{cursor: 'pointer'}}><h2>Reset</h2></button>
                </div>

                <br/>
                <span style={{color: 'red'}}>{errorMessage}</span> 
                {successMessage && <span>{successMessage}</span>}
                <br/>
                <span className='smaller-text'>Don't have an account? <Link to="/register">Register</Link></span>     
            </form>
        </div>
    );

}

export default ForgotPasswordScreen

