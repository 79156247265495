import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import './VoiceOver.css'

const VoiceOver = ({changeCompletion, slide, alreadyCompleted}) => {
    //console.log('in voiceover slide...', slide.slideNumber)

    const [isPlaying, setIsPlaying] = useState(false)
    const [isDone, setIsDone] = useState(false)

    useEffect(() => {
        if ( alreadyCompleted )
        {
            setIsDone(true)
            changeCompletion(true)
        }
        else
        {
            changeCompletion(false)
            //console.log(`set completion to false in slide's useEffect`)
        }
    }, [slide.slideNumber])

    const toggleButton = () => {
        //console.log('before setIsPlaying:', isPlaying)
        setIsPlaying(!isPlaying)
    }

    const handleCompletion = () => {
        setIsDone(true)
        changeCompletion(true)
    }

    return(
        <div className="voiceoverLayout" style={{
            backgroundSize: slide.backgroundSrc ? "contain" : "none",
            backgroundImage: slide.backgroundSrc ? `url(${slide.backgroundSrc})` : "none",
        }}>

            <svg className='playPauseSvg' viewBox={isPlaying ? "0 0 50 75" : "0 0 50 50"} width={isPlaying ? "75" : "100"} height={isPlaying ? "115" : "100"} style={isDone ? {display: 'none'} : {}} onClick={toggleButton}>
                {isPlaying 
                ? 
                    <><path d='M 10 0 L 10 75' style={{fill: '#fc6633', stroke: '#fc6633', strokeWidth:'20px'}} strokeLinejoin='round'/>
                    <path d='M 40 0 L 40 75' style={{fill: '#fc6633', stroke: '#fc6633', strokeWidth:'20px'}} strokeLinejoin='round'/></> 
                : 
                    <path d='M 0 0 L 50 25 L 0 50 z' style={{fill: '#fc6633', stroke: '#fc6633', strokeWidth:'10px'}} strokeLinejoin='round'/>
                }
            </svg>

            <ReactPlayer
                url={slide.src}
                style={isDone ? {display:'grid', gridRow:'2/3'} : {display: 'none'}}
                width='100%'
                height='56px'
                playing={isPlaying}
                controls={isDone}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload'
                        }
                    }
                }}
                onEnded={handleCompletion}
            />
        </div>
    );
}

export default VoiceOver;