import axios from 'axios'

export const sendContactUsEmail = async ({email, subject, message}) => {
    //try to send email, subject, and message to node server via Axios
    try 
    {
        const res = await axios.post("/api/public/contactus", {email, subject, message});

        if ( res.data.error )
        {
            console.log('Failed to send email:', res.data.error)
        }
        
        return res
    } 
    catch (error) 
    {
        //show error in the form
        return error
    }
}