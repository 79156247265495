import { useState, useEffect } from 'react';
import {Link, useHistory} from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider'
import './../../App.css';

/*This component is used to login users and store a JWT "authToken" in the local storage that is encrypted. It is
*retrieved by sending the password and username to the server that verifies the credentials and sends a JWT. 
*/
const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // get context for getting or modifying auth states
    const { user, logIn } = useAuth();

    let history = useHistory()

    // When first rendering the component it checks to see if the user has already been authenticated by checking
    useEffect(() => {
        if ( user.loggedIn )
        {
            history.push("/");
        }
    });
    
    //form submit handler to set email and password register data to node server for authentication
    const loginHandler = async (Event) => {
        Event.preventDefault();

        // login user and update context depending on its success
        const loginRes = await logIn({email, password})

        //console.log('loginRes:', loginRes)

        if ( loginRes )
        {
            setErrorMessage('There is no user with these credentials!')
            //Set the error message to empty in 5 sec
            setTimeout(() => {
                setErrorMessage("");
            },
            5000);
        }
    }

    return(
        <div className='login-grid-layout'>
            <form className='form' onSubmit={loginHandler}>
                <h1 className="large"> Welcome Back</h1>
                <p> Sign in to your account</p>
                <div className='form-group'>
                    <label htmlFor="email">Email:</label>
                    <input type="text" required id="email" placeholder="Enter Email" 
                    value={email} onChange={(Event) => setEmail(Event.target.value)} />  
                </div>
                        
                <div className='form-group'>   
                    <label htmlFor="password">Password:</label>
                    <input 
                        type="password" 
                        required id="password" 
                        placeholder="Password" 
                        value={password} 
                        minLength='8' 
                        onChange={(Event) => setPassword(Event.target.value)} 
                    />  
                </div>

                <div className='form-group'>
                    <div className='button-holder'> 
                        <button type="submit" style={{cursor: 'pointer'}} className="form-button"><h2>Login</h2></button>
                    </div>
                </div>
                <span className='smaller-text' style={{color: 'red'}}>{errorMessage}</span> 
                <br/>
                <span className='smaller-text' >Don't have an account? <Link to="/register">Register</Link></span>
                <br/>
                <span className='smaller-text' >Forgot your password? <Link to="/forgotpassword">Forgot Password</Link></span>
            </form>

        </div>
    );

}

export default Login