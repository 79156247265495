import axios from 'axios'
export const getUserProfile = async () => {
    const retrieveUserProfileRes = await axios.get('/api/user/profile')
    //console.log('retrieveUserDataRes:', retrieveUserProfileRes)

    if ( retrieveUserProfileRes.data.error )
    {
        return console.log('getUserProfile error:', retrieveUserProfileRes.data.error)
    }

    return retrieveUserProfileRes
}

export const updateUserProfile = async (formData) => {
    const updateProfileRes = await axios.put('/api/user/updateProfile', formData)
    //console.log('updateProfileRes:', updateProfileRes)

    if ( updateProfileRes.data.error )
    {
        return console.log('updateUserProfile error:', updateProfileRes.data.error)
    }

    return updateProfileRes
}

export const getUserStatistics = async (isAdmin) => {

    let userStatRes = null
    if ( isAdmin )
    {
        userStatRes = await axios.get('/api/user/allUserStatistics')
    }
    else
    {
        userStatRes = await axios.get('/api/user/userStatistics')
        //console.log('userStatRes:', userStatRes)
    }

    if ( userStatRes.data.error )
    {
        return console.log('getUserStatistics error:', userStatRes.data.error)
    }

    return userStatRes
}

export const getUserProgress = async () => {
    const userProgRes = await axios.get('/api/coursework/progress')
    //console.log('userProgRes:', userProgRes)

    if ( userProgRes.data.error )
    {
        return console.log('getUserProgress error:', userProgRes.data.error)
    }

    return userProgRes
}

export const saveUserProgress = async ({lastSaved, moduleCompletion}) => {
    const saveProgRes = await axios.post('/api/coursework/saveprogress', {
        lastSaved,
        moduleCompletion
    })

    //console.log('saveProgRes:', saveProgRes)

    if ( saveProgRes.data.error )
    {
        return console.log('saveUserProgress error:', saveProgRes.data.error)
    }

    return saveProgRes
}

export const deleteUser = async () => {
    const deleteRes = await axios.delete('/api/auth/deleteMyAccount')

    //console.log('authService deleteRes:', deleteRes)

    if ( deleteRes )
    {
        console.log(deleteRes.data.message)
    }
}