import { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useProgress } from '../../context/ProgressProvider'
import './FinishedModule.css'
const FinishedModule = () => {

    // clear lastSaved state, so user can restart modules or click new ones
    // without being sent to last saved slide of wrong module or last slide of same module
    const { setLastLesson } = useProgress()
    useEffect(() => {
        console.log('Finished module! Resetting last saved progress...')
        setLastLesson({module: 0, slide: 0})
    }, [])

    const history = useHistory();
    const redirectToDashboard = () => {
        history.push("/user/dashboard");
    }

    return(
        <div className='finished-module-grid-layout' style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <h1>You have finished this module!</h1>
            <button className='finishButton' onClick={redirectToDashboard}>Please Click to Continue</button>
        </div>
    );
}

export default FinishedModule;