import {useState, useEffect, useRef} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { registerUser } from '../../services/authService'
import { useAuth } from '../../context/AuthProvider'
import { accountTypes, genders, states } from '../../helper/formLists';

/*This component is used to register the user. It takes the required fields and sends them to the server.
*First it checks if the user is already logged in. If so then it immediately redirects them to the root page.
*Once registered it redirects them to the root homepage.
*/
const Register = () => {
    let history = useHistory()
    const { user, logIn } = useAuth()
    
    const [errorMessage, setErrorMessage] = useState("");

    //state functions to set state variables
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    //account type and total to purchase
    const [accountType, setAccountType] = useState("");
    const [redeemedCode, setRedeemedCode] = useState("")

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipcode, setZipcode] = useState("");

    const [birthDate, setBirthDate] = useState("");
    const [gender, setGender] = useState("");
    const [phone, setPhone] = useState("");

    //organization state setters
    const [orgName, setOrgName] = useState("");
    const [orgAddress, setOrgAddress] = useState("");
    const [orgCity, setOrgCity] = useState("");
    const [orgState, setOrgState] = useState("");
    const [orgZipcode, setOrgZipcode] = useState("");
    const [orgPhone, setOrgPhone] = useState("");

    //used to set visiblity of registration order review text
    const [isVisible, setVisiblity] = useState(false);

    // review form div is attached to this ref, so we can use it in JavaScript and scroll to div manually
    const reviewFormRef = useRef(null);
    useEffect(() => {
        if (isVisible) 
        {
            reviewFormRef.current.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"});
        }
    }, [isVisible])

    //form submit handler to set and send email, password to node server
    const registerHandler = async (Event) => {
        Event.preventDefault();


        //make sure the passwords match or set error message and reset in 5 sec
        if ( password !== confirmPassword ) 
        {
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setErrorMessage("");
            }, 5000);

            return setErrorMessage("Enter a password that matches");
        }

        const details = {   
            email, password,

            accountType,redeemedCode,

            firstName,middleName,lastName,
            address,city,state,zipcode,phone,
            birthDate,
            gender,

            orgName,orgAddress,orgCity,orgState,orgZipcode,orgPhone
        }

        try 
        {
            // save user to database
            const registerRes = await registerUser(details);

            if ( !registerRes )
            {
                setTimeout(() => {
                    setErrorMessage("");
                }, 5000);
                return setErrorMessage("Failed to register user!");
            }
            // if code check failed, make sure it came back with result before checking if its false
            else if ( registerRes.data.isValid !== undefined && !registerRes.data.isValid )
            {
                setTimeout(() => {
                    setErrorMessage("");
                }, 5000);
                return setErrorMessage("Code is not valid");
            }
            else
            {
                //console.log('Register.js registerRes:', registerRes)
                // after user is in database, log them in
                logIn({email: registerRes.data.email, password: details.password});
                history.push('/')
            }
        } 
        catch (error) 
        {
            console.log('Register frontend error:', error)
        }
    } // end of registerHandler

    const showOrgForm = () => {
        return (accountType === '' || accountType === 'Self') 
        ?
            null
        :
        (
            <>
            <h1 className='large'>Organization Information</h1>

            <div className='form-group'>  
                <label htmlFor="orgName">Organization Name:</label>
                <input 
                    type="text" 
                    id="orgName" 
                    placeholder="Organization Name" 
                    value={orgName} 
                    onChange={(Event) => setOrgName(Event.target.value)} 
                />
            </div>  

            <div className='form-group'>
                <label htmlFor="orgAddress">Address:</label>
                <input 
                    type="text" 
                    id="orgAddress" 
                    placeholder="Address" 
                    value={orgAddress} 
                    onChange={(Event) => setOrgAddress(Event.target.value)} 
                />  
            </div>

            <div className='form-group'>
                <label htmlFor="orgCity">City:</label>
                <input 
                    type="text" 
                    id="orgCity" 
                    placeholder="City" 
                    value={orgCity} 
                    onChange={(Event) => setOrgCity(Event.target.value)} 
                />        
            </div>

            <div className='form-group'>  
                <label htmlFor="orgState">State:</label>
                <select name="states" id="orgState" onChange={(Event) => setOrgState(Event.target.value)}>
                    <option value='' hidden>Select a state</option>
                    {states.map(s => <option key={s} value={s}>{s}</option>)}
                </select>
            </div>

            <div className='form-group'>  
                <label htmlFor="orgZipcode">Zip Code:</label>
                <input 
                    type="text" 
                    id="orgZipcode" 
                    placeholder="Zip Code" 
                    value={orgZipcode} 
                    onChange={(Event) => setOrgZipcode(Event.target.value)} 
                />
            </div>

            <div className='form-group'>  
                <label htmlFor="orgPhone">Phone Number:</label>
                <input 
                    type="tel" 
                     
                    id="orgPhone" 
                    placeholder="Phone" 
                    value={orgPhone} 
                    onChange={(Event) => setOrgPhone(Event.target.value)} 
                />
            </div>
            </>
        )
    }

    const showOrgInReview = () => {
        if ( accountType === '' || accountType === 'Self' )
        {
            return null
        }
        else
        {
            return (
                <div style={{backgroundColor: 'whitesmoke', padding: '25px'}}>
                    <h3>Organization: {orgName}</h3>
                    <h3>Address: {orgAddress}</h3>
                    <h3>City: {orgCity}</h3>
                    <h3>State: {orgState}</h3>
                    <h3>Zip Code: {orgZipcode}</h3>
                    <h3>Phone Number: {orgPhone}</h3>
                </div>
            )
        }
    }

    const showRedeemForm = () => {
        return (accountType === 'Self')
        ?
            <div className='form-group'>
                <label htmlFor="redeemedCode">Group Code:</label>
                <input 
                    type="text" 
                    id="redeemedCode" 
                    placeholder="Sign up under an instructor's group code" 
                    value={redeemedCode} 
                    onChange={(Event) => setRedeemedCode(Event.target.value)} 
                /> 
            </div>
        :
            null
    }

    if ( user.loggedIn === null )
    {
        return <div className='registerLoading-grid-layout'><h1>LOADING...</h1></div>
    }
    else if ( user.loggedIn )
    {
        history.push("/")
        return null
    }
    else
    {
        return (
            <div className='register-grid-layout'>
                <form className='form' onSubmit={registerHandler}>
                    <h1 className="large"> Registration</h1>

                    <div className='form-group'>
                        <label htmlFor="email">Email:</label>
                        <input 
                            type="text" 
                            required id="email" 
                            placeholder="Enter your email" 
                            value={email} 
                            onChange={(Event) => setEmail(Event.target.value)} 
                        /> 
                    </div> 
                            
                    <div className='form-group'>
                        <label htmlFor="password">Password:</label>
                        <input 
                            type="password" 
                            required id="password" 
                            placeholder="Enter Password" 
                            value={password} minLength='8' 
                            onChange={(Event) => setPassword(Event.target.value)} 
                        />  
                    </div>

                    <div className='form-group'>
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <input 
                            type="password" 
                            required id="confirmPassword" 
                            placeholder="Confirm Password" 
                            value={confirmPassword} 
                            minLength='8' 
                            onChange={(Event) => setConfirmPassword(Event.target.value)} 
                        />        
                    </div>

                    <div className='form-group'>
                        <label htmlFor="accountType">Choose Account Type: </label>

                        <select required name="accountType" id="accountType" placeholder="self" onChange={(Event) => setAccountType(Event.target.value)} >
                            <option value='' hidden>Select an account type</option>
                            {accountTypes.map(t => <option key={t} value={t}>{t}</option>)}
                        </select>
                    </div>

                    <br/>
                    <br/>

                    <div className='form-group'>  
                        <label htmlFor="firstName">First name:</label>
                        <input 
                            type="text" 
                            required id="firstName" 
                            placeholder="First Name" 
                            value={firstName} 
                            onChange={(Event) => setFirstName(Event.target.value)} 
                        />
                    </div>
                
                    <div className='form-group'>  
                        <label htmlFor="middleName">Middle name:</label>
                        <input 
                            type="text" 
                            id="middleName" 
                            placeholder="Middle Name" 
                            value={middleName} 
                            onChange={(Event) => setMiddleName(Event.target.value)} 
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor="lastName">Last Name:</label>
                        <input 
                            type="text" 
                            required id="lastName" 
                            placeholder="Last Name" 
                            value={lastName} 
                            onChange={(Event) => setLastName(Event.target.value)} 
                        /> 
                    </div> 
                            
                    <div className='form-group'>
                        <label htmlFor="address">Address:</label>
                        <input 
                            type="text" 
                            required id="address" 
                            placeholder="Address" 
                            value={address} 
                            onChange={(Event) => setAddress(Event.target.value)} 
                        />  
                    </div>
                    
                    <div className='form-group'>
                        <label htmlFor="city">City:</label>
                        <input 
                            type="text" 
                            required id="city" 
                            placeholder="City" 
                            value={city} 
                            onChange={(Event) => setCity(Event.target.value)} 
                        />        
                    </div>

                    <div className='form-group'>  
                        <label htmlFor="state">State:</label>
                        <select required name="states" id="states" onChange={(Event) => setState(Event.target.value)}>
                            <option value='' hidden>Select a state</option>
                            {states.map(s => <option key={s} value={s}>{s}</option>)}
                        </select>
                    </div>
                
                    <div className='form-group'>  
                        <label htmlFor="zipcode">Zip Code:</label>
                        <input 
                            type="text" 
                            required id="zipcode" 
                            placeholder="Zip Code" 
                            value={zipcode} 
                            onChange={(Event) => setZipcode(Event.target.value)} 
                        />
                    </div>
                
                    <div className='form-group'>
                        <label htmlFor="birthDate">Birthday:</label>
                        <input required  type='date' name='birthDate' id='birthDate' placeholder='1900-01-01' onChange={(Event) => setBirthDate(Event.target.value)} />
                    </div> 
                            
                    <div className='form-group'>
                        <label htmlFor="gender">Gender:</label>

                        <select required name="gender" id="gender" onChange={(Event) => setGender(Event.target.value)} >
                            <option value='' hidden>Select a gender</option>
                            {genders.map(g => <option key={g} value={g}>{g}</option>)}
                        </select>
                    </div>

                    <div className='form-group'>
                        <label htmlFor="phone">Phone Number:</label>
                        <input 
                            type="tel" 
                            required id="phone" 
                            placeholder="Phone" 
                            value={phone}
                            onChange={(Event) => setPhone(Event.target.value)} />  
                    </div>

                    <br/>
                    <br/>

                    {showOrgForm()}
                    
                    <div className='form-group'>
                        <br/>

                        <div className='button-holder'>
                            <button onClick={() => setVisiblity(true)} type="button" className="form-button"><h2>Review</h2></button>
                        </div>
                    
                    </div>

                    {/**This following JSX code is used to show and confirm the user register information
                     * 
                     */}

                    <div ref={reviewFormRef} style={{display: isVisible ? 'block' : 'none'}}>
                        <br/>
                        <hr/>
                        <h2>Review Registration Information</h2>
                        <div style={{margin: 10}}>
                            <div style={{backgroundColor: 'whitesmoke', marginBottom: '25px', padding: '25px'}}>
                                <h3>Email: {email}</h3>
                                <h3>First Name: {firstName}</h3>
                                <h3>Middle Name: {middleName}</h3>
                                <h3>Last Name: {lastName}</h3>
                                <h3>Address: {address}</h3>
                                <h3>City: {city}</h3>
                                <h3>State: {state}</h3>
                                <h3>Zip Code: {zipcode}</h3>
                                <h3>Gender: {gender}</h3>
                                <h3>Phone Number: {phone}</h3>
                                <h3>Account Type: {accountType}</h3>
                            </div>

                            {showOrgInReview()}
                            
                            <br/>

                        </div>
                        
                        {showRedeemForm()}

                        <div className='form-group'>
                            <br/>
                            <div className='button-holder'> 
                                <h4>Confirm Registration and Order</h4>
                                <button type="submit" className="form-button"><h2>Register</h2></button>
                            </div>
                        </div>
                        <br/>
                        <div style={{color: 'red'}}>{errorMessage}</div> 
                        <span className='smaller-text'>Already have an account? <Link to="/login">Login</Link></span>
                    </div>

                    <br/>  

                </form>
            </div>
        )
    }
}

export default Register