import {Redirect, Route} from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider'

/*This component is used to replace the <Route...> component in the App.js or anywhere you need token authentication.
It is used exactly with the same parameters and props. If the token is not set then it redirects the user to Login.js
    The reason we have initial auth state at null: https://stackoverflow.com/questions/61008916/react-check-authentication-before-render-app
*/
const PrivateRoute = ({component: Component, path}) => {
    const { user } = useAuth()
    //console.log('Private Route user status:', user)

    return(
        <Route 
            exact path={path}
            render = {props => (
                user.loggedIn 
                ?
                    <Component {...props} />
                :
                    (user.loggedIn === null 
                    ? 
                        <div className='loading-grid-layout'>
                            <h1>LOADING</h1>
                        </div> 
                    : 
                        <Redirect to='/login' />)
            )}
        />
    );

};

export default PrivateRoute;