import { useState, useEffect } from 'react';
import { useProgress } from '../../context/ProgressProvider'
import VoiceOver from '../SlideTypes/VoiceOver/VoiceOver';
import Video from '../SlideTypes/Video/Video';
import Image from '../SlideTypes/Image/Image'
import Assessment from '../SlideTypes/Assessment/Assessment';
import { useHistory } from 'react-router-dom'
import { saveUserProgress } from '../../services/userService'
import './Presentor.css'

/**This component is used to show the module lessons to the user. It has other components that are rendered
 * based what lesson that is fetched from the backend mongo database of modules lesson. The module lessons
 * are retrieved based on the module number, lessonid number, and stepPosition number. If components consists
 * of voice over, text, video, slides, and assessment and is rendered by the 'type' of json lesson recieved.
 */
const Presentor = () => {
    const [slides, setSlides] = useState(null)
    const [moduleNumber, setModuleNumber] = useState(null)

    const [currentSlide, setCurrentSlide] = useState(0)
    const [completedSlide, setCompletedSlide] = useState(false)

    const { progress, setModuleCompletion, setLastLesson } = useProgress()
    
    const history = useHistory()
    useEffect(() => {
        // if user got here not from redirect from user dashboard, send them back to user dashboard
        // otherwise, user dashboard will send with slides and moduleNumber state
        if (!history.location.state)
        {
            history.push('/user/dashboard')
        }
        else
        {
            setSlides(history.location.state.slides)
            setModuleNumber(history.location.state.moduleNumber)
        }
    }, [])

    useEffect(() => {
        /* if user refreshes/closes page, then goes to /user/dashboard to click a module card instead of /presentor:
            (note: refreshing means localStorage still contains lastSaved module+slide, moduleCompletion)
            if user clicked back into same module
                set to localStorage/backend last saved slide
            else get new module's module completion
        */
        // wait for slides and moduleNumber to load in first
        if ( slides !== null && moduleNumber !== null )
        {
            //console.log('resuming progress state:', progress)
            if ( progress.lastSaved.module === moduleNumber )
            {
                setCurrentSlide(progress.lastSaved.slide)
            }
            else
            {
                // check if we need to offset by 1 for a new module
                const newModuleStartingSlide = (progress.moduleCompletion[moduleNumber] < 0) ? 0 : progress.moduleCompletion[moduleNumber]
                setCurrentSlide(newModuleStartingSlide)
                setLastLesson({module: moduleNumber, slide: newModuleStartingSlide})
            }
        }
    }, [slides])

    const changeCompletion = (val) => {
        //console.log('setting completedSlide:', val)
        setCompletedSlide(val)
    }

    const completedCurrentSlideBefore = () => {
        return ( progress.moduleCompletion[moduleNumber] >= currentSlide )
    }

    const renderSlide = () => {
        const slide = slides[currentSlide]
        //console.log('current slide content:', slide)

        if ( slide.type === "voiceover" )
        {
            //console.log('Rendering voiceover slide...')
            return <VoiceOver key={currentSlide} changeCompletion={changeCompletion} slide={slide} alreadyCompleted={completedCurrentSlideBefore()} />
        }
        else if ( slide.type === "video" )
        {
            //console.log('Rendering video slide...')
            return <Video key={currentSlide} changeCompletion={changeCompletion} slide={slide} />
        }
        else if ( slide.type === "image" )
        {
            //console.log('Rendering image slide...')
            return <Image key={currentSlide} changeCompletion={changeCompletion} slide={slide} />
        }
        else if ( slide.type === "assessment" )
        {
            //console.log('Rendering assessment slide...')
            return <Assessment key={currentSlide} changeCompletion={changeCompletion} slide={slide} />
        }
        else
        {
            console.log("Invalid content type for Presentor to render:", slide.type);
        }
    }

    /* Slide hasn't been done before and is completed:
        Save progress to backend
        if we are at last slide
            go to finished module component
        else
            Save lastSaved module+slide and moduleCompletion into localStorage
            Increment current slide and reset slide completion
    */
    const nextLesson = async () => {
        // used to check if its the last slide
        const isLastSlide = (currentSlide === (slides.length-1))

        //console.log('completedSlide before nextLesson:', completedSlide)

        if ( progress.moduleCompletion[moduleNumber] < currentSlide && completedSlide )
        {
            // get existing module completion array and update it
            // (don't need to increment if at last slide)
            let newModuleCompletion = progress.moduleCompletion
            newModuleCompletion[moduleNumber] = currentSlide

            // save updated progress to backend
            const saveProgressRes = await saveUserProgress({
                lastSaved:{
                    module: moduleNumber,
                    slide: newModuleCompletion[moduleNumber]
                },
                moduleCompletion: newModuleCompletion
            })
            // console.log('saveProgressRes:', saveProgressRes)

            /* Once saved to backend,
                Check if its the last slide and go to finished module if so which will clear localStorage
                Else, save updated progress to localStorage
            */

            // need to update this progress locally on both cases since card completion depends on it to render the progress bar properly
            setModuleCompletion(newModuleCompletion)

            if ( saveProgressRes.data.message && isLastSlide )
            {
                history.push('/finishedmodule')
            }
            else
            {   
                //console.log('completedSlide in nextLesson:', completedSlide)

                // save updated progress to localStorage
                setLastLesson({module: moduleNumber, slide: currentSlide+1})

                // reset completion of slide for new slide and increment slide
                setCompletedSlide(false)
                setCurrentSlide(currentSlide+1)
            }
        }
        /*
        Slide has been done before (ignore completion? only save real last left off slide & module?)
            if at last slide, go to finished component
            else, save last saved progress in localStorage
        */
        else if ( completedCurrentSlideBefore() )
        {
            // check if at last slide, redirect to finished module if so
            if ( isLastSlide )
            {
                history.push('/finishedmodule')
            }
            else
            {
                // for page refreshes, no matter if slide is already completed
                setLastLesson({module: moduleNumber, slide: currentSlide+1})
                setCurrentSlide(currentSlide+1)
            }
        }
        // Slide hasn't been done and is not completed
        else if ( progress.moduleCompletion[moduleNumber] < currentSlide && !completedSlide )
        {
            console.log('Finish content on current slide before continuing!')
        }
    }
    const previousLesson = () => {
        // check if we're at beginning
        if ( currentSlide === 0 )
        {
            console.log('Cannot go back from first slide!')
        }
        else
        {
            // for page refreshes, maintain last slide
            setLastLesson({module: moduleNumber, slide: currentSlide-1})
            setCurrentSlide(currentSlide-1)
        }
    }

    /// On sent to presentor from dashboard, wait for slides and moduleNumber to load in from useEffect
    return ( slides !== null && moduleNumber !== null ) 
    ?
        <div className="presentor-grid-layout">
            <div className='slide-content'>
                {renderSlide()}
            </div>
            <div className='presentor-controls'>
                <div className='prev-btn'>
                    <svg className='leftArrow' viewBox='0 0 100 100' width='100%' height='50%' style={currentSlide === 0 ? {opacity:0.5, cursor:'not-allowed', transform:'none'} : {}}>
						<path d='M 50 0 L 100 100 L 0 100 z' style={{fill: '#fc6633', stroke: '#fc6633', strokeWidth:'20px'}} transform='rotate(-90 50 50)' strokeLinejoin='round' onClick={previousLesson}/>
					</svg>
                    <b>Previous</b>
                </div>
                <div className='slide-number'>
                    {currentSlide+1}
                </div>
                <div className='next-btn' >
                    <b>Next</b>
                    <svg className='rightArrow' viewBox='0 0 100 100' width='100%' height='50%' style={completedCurrentSlideBefore() ? {} : (!completedSlide) ? {opacity:0.5, cursor:'not-allowed', transform:'none'} : {}}>
						<path d='M 50 0 L 100 100 L 0 100 z' style={{fill: '#fc6633', stroke: '#fc6633', strokeWidth:'20px'}} transform='rotate(90 50 50)' strokeLinejoin='round' onClick={nextLesson}/>
					</svg>
                </div>
            </div>
        </div>
    :
        <div className='loading-grid-layout'>Loading Content...</div>
}
export default Presentor;