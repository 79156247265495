import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useProgress } from '../../context/ProgressProvider'
import { getModules } from '../../services/courseService'
import Card from '../card/Card'
import './UserDashboard.css';

const UserDashboard = () => {
    // Hold modules and its slides
    const [modules, setModules] = useState([]);

    // Get context state values for progress
    const { progress } = useProgress()

    /*
    Once, logged in, user clicks on user dashboard to get here:
        1. In useEffect, ([] means only run function on once on component load),
        we check if user refreshed by checking if inProgress value of true exists in localStorage.
        If so, we force onModuleClick(lastSaved.module) to get to Presentor immediately

        2. We must also get all course modules which should be a light operation since its just json objects
        
        Also, set the title and return a callback to unset the title back to ''
        (returing a callback on useEffect acts like a cleanup on component unload) 
    */
    useEffect(() => {
        // need to declare async function to work in useEffect
        const getModulesFromBackend = async () => {
            //Get all the general coursework module description from mongodb via the server
            const courseRes = await getModules();
            if ( courseRes )
            {
                //console.log('courseRes.data:', courseRes.data)
                setModules( courseRes.data.sort((a,b) => a.moduleNumber - b.moduleNumber) ); // cannot trust mongoDB sorted order
                localStorage.setItem('moduleContent', JSON.stringify(courseRes.data))
            }
        }

        // store modules in localStorage and retrieve them if they exist 
        // (saves a database call if user refreshed)
        const storedModuleContent = JSON.parse(localStorage.getItem('moduleContent'))
        if ( storedModuleContent )
        {
            // console.log('Found storedModuleContent...', storedModuleContent )
            setModules(storedModuleContent)
        }
        else
        {
            console.log('Getting modules from backend...')
            getModulesFromBackend();
        }
    }, [])

    // check if moduleNumber passed in should be locked because a previous module needs to be completed
    const isModuleUnlocked = (moduleNumber) => {
        // check modules are completed in order
        // if at start, moduleNumber 0, don't check
        if ( moduleNumber < 1 )
        {
            return true
        }
        // else, check if moduleCompletion of previous module from module clicked is maxed out at previous modules length-1 (-1 to get actual last index)
        else
        {
            return (progress.moduleCompletion[moduleNumber-1] === modules[moduleNumber-1].slides.length-1)
        }
    }

    // show list of clickable cards with module info
    const showModules = () => {
        return modules 
        ? 
        (
            modules.map((m, idx) => {
                return (
                    <Card
                        key={m.moduleNumber}
                        moduleNumber={m.moduleNumber}
                        title={m.title} 
                        description={m.description}
                        moduleCompletion={progress.moduleCompletion[idx]}
                        slideLength={m.slides.length}
                        onModuleClick={onModuleClick}
                        isUnlocked={isModuleUnlocked(idx)}
                    />
                )
            })
        )
        :
        <h1>LOADING MODULES...</h1>
    }

    const history = useHistory()
    // when clicking a module, it passes in number to this UserDashboard's currentModule state
    const onModuleClick = (moduleNumber) => {
        // show Presentor component with current module and its slides
        history.push('/presentor', {
            moduleNumber: moduleNumber, 
            slides: modules[moduleNumber].slides
        })
    }

    return (
        <div className="user-dashboard-grid-layout">
            {showModules()}
        </div>
    );
}

export default UserDashboard;