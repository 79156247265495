import React, { useState, useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom'
import { getUserProfile, updateUserProfile } from '../../services/userService'
import FormReducer from '../../reducers/FormReducer'
import { genders, accountTypes, states } from '../../helper/formLists'
import { useAuth } from '../../context/AuthProvider'
import { deleteUser } from '../../services/userService'
import { useHistory } from 'react-router-dom'
import './Profile.css'

const Profile = () => {
    // also used to compare if profile really changed, to save backend queries
    let initOldFormData = {
        email: '',
        role: '',accountType: '', firstName: '',middleName: '', lastName: '',
        address: '', city: '',state: '',zip: '',
        birthDate: '',
        gender: '',
        phone: '',
        groupCode: '',
        redeemedCode: '',
        org: {
            name: '',
            address: '',
            city:'',
            state:'',
            phone:'',
            type:'',
            zip:''
        }
    }
    const [oldFormData, setOldFormData] = useState(initOldFormData)

    // retrieve user data
    useEffect(() => {
        const setInitialUserData = async () => {
            const getProfileRes = await getUserProfile()

            if ( getProfileRes )
            {
                dispatch({
                    type:'INIT_FORM',
                    payload: getProfileRes.data
                })

                setOldFormData({...getProfileRes.data})
            }
        }

        setInitialUserData()
    }, [])

    const initialFormState = {
        email: '',
        role: '',accountType: '', firstName: '',middleName: '', lastName: '',
        address: '', city: '',state: '',zip: '',
        birthDate: '',
        gender: '',
        phone: '',
        groupCode: '',
        redeemedCode: '',
        org: {
            name: '',
            address: '',
            city:'',
            state:'',
            phone:'',
            type:'',
            zip:''
        }
    }
    const [formData, dispatch] = useReducer(FormReducer, initialFormState)

    // used to change visual and logic state on save changes button
    const [changed, setChanged] = useState(false)
    const [updating, setUpdating] = useState(false)

    const updateForm = (e) => {
        //console.log('e.target:', e.target)

        // to save update queries on duplicate forms
        // if user modified some fields and reset them, we need to check if its a duplicate again
        // cannot only check individual changes since user can reset a single field to trigger isDuplicate which disables saving actually changed fields
        let isDuplicate = true
        for ( const field in oldFormData )
        {   
            // got to org field, do a inner loop
            if ( e.target.name.substring(0, 3) === 'org' || field.substring(0, 3) === 'org' )
            {
                for ( const orgField in (oldFormData.org) )
                {
                    if ( e.target.name.substring(4) === orgField )
                    {
                        //console.log('oldFieldData:', formData['org'][orgField], '/ newFieldData:', e.target.value)
                        if ( e.target.value !== oldFormData['org'][orgField] )
                        {
                            isDuplicate = false
                        }
                    }
                    else if ( oldFormData['org'][orgField] !== formData['org'][orgField] )
                    {
                        isDuplicate = false
                    }
                }
            }
            else
            {
                if ( e.target.name === field) 
                {
                    //console.log('oldFieldData:', oldFormData[field], '/ newFieldData:', e.target.value)
                    if ( e.target.value !== oldFormData[field] )
                    {
                        isDuplicate = false
                    }
                }
                else if ( oldFormData[field] !== formData[field] )
                {
                    isDuplicate = false
                }
            }
        }

        // if it failed to find a single mismatch, then form did not change, save an update query
        if ( isDuplicate ) 
        {
            setChanged(false)
        }
        else if ( !changed )
        {
            setChanged(true)
        }

        dispatch({
            type: 'UPDATE_FORM',
            payload: {
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const subFieldMaker = ( subFields ) => {
        const inputStyle = {
            display: 'grid',
            width: '100%',
            height: '30px'
        }

        if ( subFields )
        {
            // used to setup input attributes that match equivalent named states
            let names = Object.keys(subFields)
            //console.log('Object.keys(subFields):', names)
            
            // test if it's an org object, meaning we need to append org to get a field (ex: org.name)
            const isOrg = ( names[0] === 'org' )

            // also need to extract org object's inner objects
            if ( isOrg )
            {
                names = Object.keys({...subFields[names[0]]})
            }

            return names.map( f => {
                if ( f === 'birthDate' )
                {
                    return <input key={f} type='date' name='birthDate' value={formData[f]} onChange={(e) => updateForm(e)}/>
                }
                else if ( f === 'accountType' || f === 'type' )
                {
                    return (
                        <select key={f} name={isOrg ? `org.${f}` : f} value={isOrg ? formData['org'][f] || '' : formData[f] || ''} disabled={user.role === 'admin'} onChange={(e) => updateForm(e)}>
                            <option value='' hidden>Please select an account type</option>
                            {accountTypes.map(t => <option key={t} value={t} hidden={isOrg && t === 'Self'}>{t}</option>)}    
                        </select>
                    )
                }
                else if ( f === 'state' )
                {
                    return (
                        <select key={f} name={isOrg ? `org.${f}` : f} value={isOrg ? formData['org'][f] || '' : formData[f] || ''} onChange={(e) => updateForm(e)}>
                            <option value='' hidden>Please select a state</option>
                            {states.map(s => <option key={s} value={s}>{s}</option>)}
                        </select>
                    ) 
                }
                else if ( f === 'gender' )
                {
                    return (
                        <select key={f} name={f} value={formData[f]} onChange={(e) => updateForm(e)}>
                            {genders.map(g => <option key={g} value={g}>{g}</option>)}
                        </select>
                    )
                }
                else if ( f === 'groupCode' || f === 'redeemedCode' )
                {
                    // only give accounts with user role ability to sign up under different group codes
                    return ( formData.accountType === 'Self' )
                    ? 
                        <input key={'redeemedCode'} name={'redeemedCode'} placeholder={`Sign up under an instructor's group code`} value={formData.redeemedCode || ''} style={inputStyle} disabled={user.role === 'admin'} onChange={(e) => updateForm(e)} />
                    : 
                        <input key={'groupCode'} name={'groupCode'} value={formData.groupCode || 'Save changes to generate new group code'} style={inputStyle} disabled={true} />
                }
                else
                {
                    return <input key={f} name={isOrg ? `org.${f}` : f} placeholder={f} value={isOrg ? formData['org'][f] :  formData[f]} style={inputStyle} onChange={(e) => updateForm(e)} />
                }
            })
        }
    
        return null
    }
    
    const fieldMaker = (field, subFields) => {
        const fieldStyle = {
            display: 'grid',
            background: '#105e7a',
            color: 'white',
            padding: '5px 15px 0px 15px',
            height: '35px',
        }
    
        return (
            <div style={{margin: '0px 0px 15px 0px'}}>
                <div style={fieldStyle}> {field} </div> 
                {subFieldMaker(subFields || null)}
            </div>
        );
    }

    const handleUpdateProfile = async (e) => {
        // disable save changes button and change its text visually to let user know to wait for update to finish
        setUpdating(true)

        // check if user entered the same code, meaning we shouldn't update instructor's userGroup in backend
        const finalFormData = {...formData}

        const updateRes = await updateUserProfile(finalFormData)
        if ( updateRes )
        {
            console.log('Successfully updated profile in backend!')

            // if user switches to instructor account, backend sends back a generated group code,
            // update our group code state
            dispatch({
                type: 'UPDATE_FORM',
                payload: {
                    name: 'redeemedCode',
                    value: updateRes.data.redeemedCode
                }
            })

            // reset save changes button states
            setUpdating(false)
            setChanged(false)
        }
        else
        {
            console.log('Failed to update profile in backend!')
            setUpdating(false)
            setChanged(false)
        }
    }

    // Don't show org section if user signed up as 'Self' account type
    const showOrg = () => {
        return ( formData.accountType !== 'Self' ) 
        ? 
            <div className='org-layout'>
                <div className='section-header'> ORGANIZATION INFORMATION </div>
                <div className='section-fields'>
                    {fieldMaker('ORGANIZATION', {org:{...formData.org}})}
                </div>
            </div>
        :
            null
    }

    const saveDivStyle = {
        display: 'grid', 
        width:'100%',
        height:'100%', 
        justifyContent: 'center'
    }

    const deleteFormStyle = {
        display: 'grid',
        gridTemplateRows: 'min-content min-content auto',
        gridRowGap:'15px',
        gridTemplateColumns: '0.5fr 0.5fr',
        justifyItems: 'center',
        alignItems: 'center',

        borderRadius: '15px',
        height: '300px',
        width: '600px',
        margin: 'auto',
        position: 'absolute',
        top: '0', left: '0', bottom: '0', right: '0',
        zIndex: '2',

        padding: '25px 0px 25px 0px',
        boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.25)',
        backgroundColor: 'whitesmoke',
        textAlign: 'center',
        fontSize: '25px',
        fontWeight: '400',
    }
    const [deleteFormVisible, setDeleteFormVisible] = useState(false)
    const [confirmEmail, setConfirmEmail] = useState('')
    let history = useHistory()
    const { user, logOff } = useAuth()
    
    const confirmDeletion = async (e) => {
        e.preventDefault()

        // type in exact email to confirm deletion and hide form
        if ( confirmEmail === user.email )
        {
            deleteUser()
            logOff()
            history.push('/')
        }
    }

	return (
		<div className='profile-grid-layout' style={formData.accountType === 'user' ? {display: 'grid', gridTemplateRows:'minmax(0,min-content) auto'} : null}>
            <div className='top-row-layout'>
                <div className='personal-info-layout'>
                    <div className='section-header'> PERSONAL INFORMATION </div>
                    <div className='section-fields'>
                        {fieldMaker('NAME', {firstName: formData.firstName, middleName: formData.middleName, lastName: formData.lastName})}
                        {fieldMaker('ADDRESS', {address: formData.address, city: formData.city, state: formData.state, zip: formData.zip})}
                        {fieldMaker('PHONE', {phone: formData.phone})}
                        {fieldMaker('BIRTHDAY', {birthDate: formData.birthDate})}
                        {fieldMaker('GENDER', {gender: formData.gender})}
                    </div>
                </div>

                <div className='account-info-layout'>
                    <div className='section-header'> ACCOUNT INFORMATION </div>
                    <div className='section-fields'>
                        {fieldMaker('EMAIL', {email: formData.email})}
                        {fieldMaker('ACCOUNT TYPE', {accountType: formData.accountType})}
                        {fieldMaker('GROUP CODE', {groupCode: formData.groupCode})}
                        <Link to='/forgotpassword' className='resetBtn'> Reset Your Password </Link>
                        <button className='deleteBtn' onClick={() => setDeleteFormVisible(true)} disabled={user.role === 'admin'}>Delete Your Account</button>
                    </div>
                </div>
            </div>
            {showOrg()}
            <div style={saveDivStyle}>
                <button style={{
                        opacity: (!changed || updating) ? '0.5' : '1',
                        cursor: (!changed) ? 'not-allowed' : (updating) ? 'waiting': 'pointer' 
                    }} 
                    className='saveChangesBtn' 
                    disabled={!changed || updating} 
                    onClick={() => handleUpdateProfile()}
                >
                    {updating ? `Saving...` : `Save Changes`}
                </button>
            </div>
            <div style={deleteFormVisible ? deleteFormStyle : {display: 'none'}}>
                <div style={{display:'grid', gridRow:'1/2', gridColumn:'1/3', borderBottom: '1px solid rgba(0,0,0,0.25)', width: '95%', paddingBottom:'15px'}}>Enter your email to confirm deletion: {user.email}</div>
                <input style={{display:'grid', gridRow:'2/3', gridColumn:'1/3', fontSize: '20px', marginTop: '5px'}} type='text' value={confirmEmail} onChange={e => setConfirmEmail(e.target.value)}/>
                <button style={{
                            display:'grid', 
                            gridRow: '3/4',
                            gridColumn: '1/2', 
                            opacity: `${(confirmEmail !== user.email) ? '0.5' : '1'}`,
                            cursor: `${(confirmEmail !== user.email) ? 'not-allowed' : 'pointer'}`,
                            backgroundColor: 'var(--primary-color)',
                            width: '75%',
                            borderRadius: '5px'
                        }} 
                    disabled={confirmEmail !== user.email} 
                    onClick={e => confirmDeletion(e)}
                >
                    Delete Email
                </button>
                <button style={{display:'grid', gridRow: '3/4', gridColumn: '2/3', backgroundColor: 'var(--primary-color)', width: '75%', borderRadius: '5px'}} onClick={e => {
                    setDeleteFormVisible(false)
                    setConfirmEmail('')
                }}
                >
                    Cancel
                </button>
            </div>
        </div>
	);
}

export default Profile