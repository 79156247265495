import './AboutUs.css'

const AboutUs = () => {
    return(
        <div className = 'aboutus-grid-layout'>
            <div className = 'aboutus-text'>
                <h2>Overview</h2>
                <p className = 'paragraphtext'>
                When a person commits an act of targeted violence at school, the act often involves significant planning. 
                Schools can support their community and help prevent acts of targeted violence by establishing an “Assessment and Care Team,” also known as a “Behavioral Intervention Team” or a “Threat Assessment Team.” 
                This interface provides a condensed overview of a school Assessment and Care Team. 
                It outlines how a school or school district can form and operate an Assessment and Care Team. 
                The content is based on information published by the U.S. Secret Service National Threat Assessment Center.
                </p>
            </div>
        </div>
    )
}
export default AboutUs;