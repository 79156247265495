import { useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

/**This component is used to reset the user password. This page/component is accessed through a link sent by an email.
 * The user enters a new password and confirms it. It must be 8 characters or more and match. The password is 
 * send to the server backend. A button appears that the user can click to redirect to "/login" 
 */
const ResetPasswordScreen = ( { history, match } ) => {

    //state functions to set state variables
    const [password, setPassword] = useState("");
    const [confirmPassword, setComfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);

    // logout and redirect to user to "/"
    const loginHandler = () => {
        history.push("/login");
    }

    //form submit handler to set and send email, password to node server for authentication
    const resetPasswordHandler = async (Event) => {
        Event.preventDefault();

        //make sure the passwords match or set error message and reset in 5 sec
        if( password != confirmPassword ) {
            setTimeout(() => {
                setErrorMessage("");
            }, 5000);

            return setErrorMessage("Enter a password that matches");
        }

        //try to send register data to node server via Axios
        try 
        {
            const serverData = await axios.put(`/api/auth/resetpassword/${match.params.resetToken}`, {password});

            if ( serverData && !serverData.data.error )
            {
                //set input text boxes to blank
                setPassword("");
                setComfirmPassword("");

                //set response lines in the page
                console.log(serverData.data.message)
                setSuccess(serverData.data.success);
            }
            else
            {
                setTimeout(() => {
                    setErrorMessage("");
                },
                5000);
                setErrorMessage('Failed to send email!')
            }
        } 
        catch (error) 
        {
            //show the error i the page
            console.log(error)
            setErrorMessage(error.response.data.error);

            //Set the error message to empty in 5 sec
            setTimeout(() => {
                setErrorMessage("");
            },
            5000);
        }

    }//resetPasswordHandler

    return(
        <div className='reset-password-grid-layout' style={{
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            margin: '50px'
        }}>
            <h1 style={{justifySelf: 'center'}}>Reset Your Password</h1>

            <form onSubmit={resetPasswordHandler} className='form'>
                <div className='form-group'>  
                    <label htmlFor="password">New Password:</label>
                    <input type="password" minLength='8' required id="password" placeholder="Enter Password" 
                    disabled={success} value={password} onChange={(Event) => setPassword(Event.target.value)} />  
                </div>

                <div className='form-group'>  
                    <label htmlFor="comfirmpassword">Confirm Password:</label>
                    <input type="password" minLength='8' required id="comfirmpassword" placeholder="Comfirm Password" 
                    disabled={success} value={confirmPassword} onChange={(Event) => setComfirmPassword(Event.target.value)} />  
                </div>
                    
                <div className='form-group'>        
                    <button disabled={success} style={success ? {opacity: '0.5', cursor:'default'} : {cursor: 'pointer'}} type="submit">Reset Password</button>
                </div>

                <br/>
                {success ? (<><span>You have successfully changed your password!</span><br/><button style={{cursor: 'pointer'}} onClick={loginHandler}>Login</button></>) : null}
                <span className='smaller-text' style={{color: 'red'}}>{errorMessage}</span> 
                <br/>
                <br/>
                <span className='smaller-text' >Don't have an account? <Link to="/register">Register</Link></span>
                <br/>
                <span className='smaller-text' >Forgot your password? <Link to="/forgotpassword">Forgot Password</Link></span>
            </form>
          
        </div>
    );

}

export default ResetPasswordScreen