import React, { useState, useEffect, useRef } from 'react';
import './Assessment.css';

const Assessment = ({changeCompletion, slide}) => {
    //console.log('in assess slide...', slide.slideNumber)

    useEffect(() => {
        changeCompletion(false)
        //console.log(`set completion to false in slide's useEffect`)

        setChoice(0)
        setSubmittedChoice(-1)
    }, [slide.slideNumber])

    const quiz = slide
    /*
    onChange switches choice,
    don't check answer or show anything until user submits
    then, we show results on submitted choice
    otherwise, results will still show and move to other radio btns in onChange
     */
    const [choice, setChoice] = useState(0) // for onChange and checking answer once user submits
    const [submittedChoice, setSubmittedChoice] = useState(-1) // for visual purposes
    const [submittedCorrectAnswer, setSubmittedCorrectAnswer] = useState(false)

    const explanationRef = useRef()
    useEffect(() => {
        if (submittedCorrectAnswer)
        {
            explanationRef.current.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"});
        }
    }, [submittedCorrectAnswer])

    // return a quiz button, choice, and a result mark for each choice
    const showChoices = () => {
        return quiz.choices.map((c, idx) => {
            return (
                <div className='choiceRow' key={c}>
                    <input
                        type='radio'
                        name='choices'
                        value={idx}
                        defaultChecked={idx === 0}
                        onChange={(e) => setChoice(parseInt(e.target.value))}
                        disabled={submittedCorrectAnswer}
                    />
                    <label className={showResult(idx)}>
                        {c}
                    </label>
                </div>
            )
        })
    }

    // if user submitted a choice, show if its the correct or wrong answer
    const showResult = (idx) => {
        if ( submittedChoice === idx )
        {
            if ( idx === quiz.answer )
            {
                return 'labelCorrect'
                //return <div className='greenCheckMark'>Correct!</div>
            }
            else
            {
                return 'labelWrong'
                //return <div className='redXMark'>Wrong Answer!</div>
            }
        }
    }

    // on submit, set the choice that the user submitted and check if its the correct or wrong answer
    const checkAnswer = (e) => {
        e.preventDefault()
        
        //console.log('setting submitted choice...')
        setSubmittedChoice(choice)
        if (choice === quiz.answer)
        {
            changeCompletion(true)
            setSubmittedCorrectAnswer(true)
            //console.log('Correct answer!')
        }
        else
        {
            //console.log('Wrong answer:', choice)
        }
    }

    // if user selected the correct choice, automatically show explanation
    const showExplanation = () => {
        return ( submittedCorrectAnswer ) 
        ? 
            <>
                <b>Explanation</b>
                <br></br>
                <p ref={explanationRef}>{slide.explanation}</p> 
            </>
        : 
            null
    }

    return (
        <div className='assessment-layout'>
            <div className='questionNumber'>
                Question {quiz.questionNumber}:
            </div>
            <div className='question'>
                {quiz.question}
            </div>
            <form className='choicesForm' onSubmit={checkAnswer}>
                {showChoices()}
                <button className='quizBtn' type="submit"  disabled={submittedCorrectAnswer}>
                    Submit
                </button>
            </form>
            <div className='explanation' style={submittedChoice !== quiz.answer ? {display: 'none'} : {}}>
                {showExplanation()}
            </div>
        </div>
    )
}

export default Assessment