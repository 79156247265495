export const videoData = [
    {
        id: 0,
        title: "Assessment and Care Team Process",
        overview: "This training module will teach you about the Assessment and Care Team Process, which schools can use to help prevent targeted violence.",
        thumbnail_src: '/resources/slides/module1/module1_slide1.png',
    },
    {
        id: 1,
        title: "Implementing An Assessment And Care Team Process",
        overview: "The next section of the training module covers how to implement an Assessment and Care Team Process.",
        thumbnail_src: '/resources/slides/module2/module2_slide1.png',
    },
    {
        id: 2,
        title: "Establish The Inquiry Procedure",
        overview: "The second step in developing an Assessment and Care Process is to establish the Team’s inquiry procedure.",
        thumbnail_src: '/resources/slides/module3/module3_slide1.png',
    },
    {
        id: 3,
        title: "Establish The Response Procedure",
        overview: "The third step to developing an Assessment and Care Team Process is to establish the Team’s response procedure.",
        thumbnail_src: '/resources/slides/module4/module4_slide1.png',
    },
    {
        id: 4,
        title: "Train Community Members",
        overview: "The fourth and final step in developing an Assessment and Care Team Process is to train community members",
        thumbnail_src: '/resources/slides/module5/module5_slide1.png',
    },
    {
        id: 5,
        title: "Creating a Safe School Climate",
        overview: "Identify the goals schools can set to create a safe school climate and the specific actions they can pursue to achieve it.",
        thumbnail_src: '/resources/slides/module6/module6_slide1.png',
    }
];
