const FormReducer = (state, action) => {
    switch ( action.type )
    {
        case 'INIT_FORM':
            return {
                ...state,
                ...action.payload
            }
        case 'UPDATE_FORM':
            // if its an org field, we must update specific field inside org object
            if ( action.payload.name.substring(0, 3) === 'org' )
            {
                return {
                    ...state,
                    org: {
                        ...(state.org),
                        [action.payload.name.substring(4)]: action.payload.value
                    }
                }
            }
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }        
        default:
            return state
    }
}

export default FormReducer