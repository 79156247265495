import './Card.css';

//This card props has the particular ONE module that it represents
const Card = ({moduleNumber, title, description, moduleCompletion, slideLength, onModuleClick, isUnlocked}) => {
    /* 
    {startModule && <span style={{color: "green"}}><h3>Start</h3></span>}
    {completedModule && <span style={{color: "lightskyblue"}}><h3>Completed</h3></span>}
    {inprogressModule && <span style={{color: "yellow"}}><h3>In Progress</h3></span>}
    {nextModule && <span style={{color: "red"}}><h3>Next</h3></span>}
    */
    const showButton = () => {
        if ( !isUnlocked )
        {
            return <button disabled>Finish Previous Modules</button>
        }
        else if ( moduleCompletion < 0 )
        {
            return <button>Click to Start</button>
        }
        else if ( moduleCompletion >= (slideLength-1) )
        {
            return <button>Click to Review</button>
        }
        else if ( moduleCompletion < (slideLength-1) )
        {
            return <button>Click to Continue</button>
        }
    }

    return(
        <div className={isUnlocked ? 'cardContainer' : 'cardContainerLocked'} onClick={isUnlocked ? () => onModuleClick(moduleNumber) : null} >
            <div className="cardTitle">
                <h3>Module {moduleNumber+1}</h3>
                <h4>{title}</h4>
            </div>
            <div className="cardDescription">
                <p>{description}</p>
            </div>
            <div style={{
                textAlign: 'center'
            }}>
                {moduleCompletion+1} / {slideLength}
            </div>
            <div className='progressBar'>
                <span style={{
                    width: `${((moduleCompletion+1)/slideLength)*100}%`
                }}>
                </span>
            </div>
            <div className="card-btn" style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '0px',
                margin: '0px'
            }}>
                {showButton()}
            </div>
        </div>
        );
}
export default Card;