import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';

const Video = ({changeCompletion, slide}) => {
    //console.log('in video slide...', slide.slideNumber)

    useEffect(() => {
        changeCompletion(false)
        //console.log(`set completion to false in slide's useEffect`)
    }, [slide.slideNumber])

    const videoLayout = {
        display: 'grid',
        width: '100%',
        height: '100%',
        alignItems:'center',
        justifyItems:'center',
    }

    return(
        <div className="video-layout" style={videoLayout}>
            <ReactPlayer
                width='auto'
                height='auto'
                url={slide.src}
                playing={false}
                controls={true}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload'
                        }
                    }
                }}
                onEnded={() => changeCompletion(true)}
            />
        </div>
    )
}
export default Video;