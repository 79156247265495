import React, { useState, useEffect, useRef } from 'react';
import './Image.css'

const Image = ({changeCompletion, slide}) => {
    //console.log('in image slide...', slide.slideNumber)
    const [ showText, setShowText ] = useState(false)
    const expandedTextRef = useRef()

    useEffect(() => {
        if (showText) 
        {
            changeCompletion(true)
            expandedTextRef.current.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"});
        }
        else
        {
            changeCompletion(false)
        }
    }, [slide.slideNumber, showText])


    const expandTextButton = () => {
        setShowText(true)
    }
    
    const imageLayout = {
        display: 'grid',
        alignItems:'start',
        justifyItems:'center'
    }

    return (
        <div className="image-layout" style={imageLayout}>
            <img src={slide.src}></img>
            <button disabled={showText} onClick={expandTextButton}>Read More</button>
            <div className={showText ? 'text-explanation' : 'hide-text-explanation'}>
                <b>Explanation</b>
                <br></br>
                <br></br>
                <p ref={expandedTextRef}>
                    {slide.text}
                </p>
            </div>
        </div>
    );
}

export default Image;