import { useHistory } from 'react-router-dom';
import {videoData} from './trainingData';

import './TrainingVideos.css';

const TrainingVideos = () => {
    const history = useHistory();
    console.log(history);
	return (
        <div className='training-videos-grid-layout'>
            <div className='flex-col'>
                {videoData.map((value) => {
                    let default_thumbnail = 'https://thumbs.dreamstime.com/b/no-thumbnail-image-placeholder-forums-blogs-websites-148010362.jpg';

                    return <div key={value.id} className='flex-row' style={{cursor: 'pointer'}} onClick={() => history.push('/traininginfo/' + value.id)}>
                        <div className="inner-row-white">
                            <div className="inner-row-container left">
                                <div className='thumbnail'>
                                    <img style={{'borderRadius': '10px', width:'100%'}} width='300' height='260' src={value.thumbnail_src ? value.thumbnail_src : default_thumbnail} />
                                </div>
                            </div>

                            <div className='inner-row-container right'>
                                <div className='inner-details'>
                                    <h2>{value.title}</h2>
                                    <h3>Short Overview</h3>
                                    <p className='overview'>{value.overview}</p>
                                </div>
                            </div>
                        </div>
                    </div>;
                })}
            </div>
        </div>
	);
}

export default TrainingVideos;
