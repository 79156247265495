import React, { useState, useEffect } from 'react';
import { videoData } from '../TrainingVideos/trainingData';

import './TrainingVideoView.css';

const TrainingVideoView = (props) => {
    const [trainingVideo, setTrainingVideo] = useState(null);
    let trainingVideoId = props.match.params.trainingVideoId;
    let default_thumbnail = 'https://thumbs.dreamstime.com/b/no-thumbnail-image-placeholder-forums-blogs-websites-148010362.jpg';

    useEffect(() => {
        let tv = videoData.find(v => v.id == trainingVideoId);
        if (tv != null) {
            setTrainingVideo(tv);
            console.log(trainingVideo);
        }
    });

	return (
        <div className='training-video-grid-layout'>
            <div className='outer-container'>
                {
                    trainingVideo == null ? <p style={{'textAlign': 'center', 'marginTop': '50px'}}>Invalid Training Video</p> :
                        (<div className="inner-container">

                            <div className="innerHalf left videoPreview">
                                <img style={{'borderRadius': '10px', width:'100%'}} width='350' height='350' src={trainingVideo.thumbnail_src ? trainingVideo.thumbnail_src : default_thumbnail} />

                            </div>
                            <div className="innerHalf right secondary-info">
                                <h2>{trainingVideo.title}</h2>
                                <div className="description">
                                    <p>
                                        {trainingVideo.overview}
                                    </p>
                                </div>
                            </div>
                        </div>)
                }
            </div>
        </div>
	);
}

export default TrainingVideoView;
