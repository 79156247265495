import axios from 'axios'

export const registerUser = async (details) => {
    // save user to database
    const registerRes = await axios.post("/api/auth/register", details);
    //console.log('authService registerRes:', registerRes)

    if ( registerRes.data.error )
    {
        return console.log('registerUser error:', registerRes.data.error)
    }

    return registerRes
}

export const loginUser = async ({email, password}) => {
    const loginRes = await axios.post("/api/auth/login", {email, password})
    //console.log('authService loginRes:', loginRes)

    if ( loginRes.data.error )
    {
        console.log('loginUser error:', loginRes.data.error)
        // need to return error to frontend to display to user
    }

    return loginRes
}

export const authenticateUser = async () => {
    try
    {
        const authRes = await axios.get('/api/auth/session')
        //console.log('authService authRes:', authRes)
        if ( !authRes.data.error )
        {
            return authRes
        }
        else
        {
            console.log('No existing user session in database!')
        }
    }
    catch (error)
    {
        console.log('authService authenticate error:', error)
    }
}

export const logoutUser = async () => {
    const logoutRes = await axios.post('/api/auth/session/logout')
    //console.log('authService logoutRes:', logoutRes)
    if ( logoutRes )
    {
        console.log(logoutRes.data.message)
    }
}