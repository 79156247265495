import { useState, useEffect } from "react";
import { sendContactUsEmail } from "../../services/publicService";
import { useAuth } from '../../context/AuthProvider'
import "./ContactUs.css";

const ContactUs = () => {
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { user } = useAuth()
    useEffect(() => {
        if ( user.loggedIn )
        {
            setEmail(user.email)
        } 
    },[])

    const contactUsHandler = async (Event) => {
        Event.preventDefault();
        try
        {
            const res = await sendContactUsEmail({email, subject, message})
            if ( res )
            {
                setSuccessMessage(res.data.message);
            }
        } 
        catch (error) 
        {
            //show error in the form
            setErrorMessage(error.response.data.error);
            //clear the email text box
            //setEmail("");
        }
    }

    return(
        <div className="contactForm-grid-layout" style={{background: 'var(--primary-color)'}}>
            <form className="form" onSubmit={contactUsHandler}>
                <h1 className="large">Contact Us</h1>
                <div className='form-group'>
                    
                    <label htmlFor="email"><h2>Your Email:</h2></label>
                    <input type="text" required id="email" placeholder="Enter Your Email" 
                        value={email} onChange={(Event) => setEmail(Event.target.value)} />

                </div>

                <div className='form-group'>
                    
                    <label htmlFor="subject"><h2>Subject</h2></label>
                    <input type="text" required id="subject" placeholder="Enter Subject" 
                        value={subject} onChange={(Event) => setSubject(Event.target.value)} />

                </div>

                <div className='form-group'>
                    
                    <label htmlFor="message"><h2>Message</h2></label>
                    <textarea style={{fontFamily: 'arial'}} cols='100' rows='10' type="text" required id="message" placeholder="Enter Your Message" 
                        value={message} onChange={(Event) => setMessage(Event.target.value)} />

                </div>

                <button type="submit" className='form-button'>Send Email</button>

                <br/>
                {errorMessage && <p>Error: {errorMessage}</p>}
                {successMessage && <p>{successMessage}</p>}

            </form>
        </div>
    );
}

export default ContactUs;