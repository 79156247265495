/*
Initial State:
    email: "",
    role: "",
    loggedIn: null,
*/

const UserReducer = (state, action) => {
    switch(action.type) 
    {
        case 'LOGIN_USER':
            return {
                loggedIn: true,
                email: action.payload.email,
                role: action.payload.role
            }

        case 'LOGOFF_USER':
            return {
                loggedIn: false,
                email: ""
            }
        default:
            return state;
    }
}

export default UserReducer