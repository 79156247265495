import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import './ProfileBar.css'

const ProfileBar = () => {
    const { user, logOff } = useAuth();
    const history = useHistory();
    
    const [ showMenu, setShowMenu ] = useState(false)

    useEffect(() => {
        if ( user.loggedIn )
        {
            document.addEventListener('click', avatarClick)
        }
    }, [user.loggedIn])


    const avatarClick = (event) => {
        //console.log('event.target.id:', event.target.id)

        if ( event.target.id === 'profile-icon' )
        {
            setShowMenu(!showMenu)
        }
        else
        {
            setShowMenu(false)
        }
    }

    //used in logout buttonn to logout, remove token, and redirect to user to "/"
    const logoutHandler = () => {
        logOff();
        setShowMenu(false)
        history.push("/");
    }

    const showProfileMenu = () => {
        return showMenu
        ?
        <div className='profile-menu'>
            <ul style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center'
            }}>
                <li style={{
                    color: 'black',
                    borderBottom: '1px solid black',
                    padding: '0px 30px 0px 30px',
                    margin: '0px 10px 0px 10px',
                    fontWeight: '500'
                }}><Link to='/profile'>My Profile</Link></li>
                <li>
                    <button 
                        className='pBarLogoutBtn'
                        onClick={logoutHandler}
                    >
                        Logout
                    </button>
                </li>
            </ul>
        </div>
        :
        null
    }

    // only show role if user is instructor or an adin
    const showRole = () => {
        if (user.role === 'admin' || user.role === 'instructor')
        {
            return `${user.role.charAt(0).toUpperCase() + user.role.slice(1)}: `
        }

        return ''
    }

    const showRegisterAndLogin = () => {
        if (user.loggedIn)
        {
            return (
                <>
                    <li style={{color: 'white'}}>
                        {`${showRole()}${user.email}`}
                    </li>
                    <li>
                        <img 
                            id='profile-icon'
                            style={{
                                background: "white", 
                                cursor:'pointer',
                            }}
                            src="/profile_icon.svg" alt="profileIcon" 
                            height='40px' 
                        />
                    </li>
                    <li>
                    <button className='frontPageLogoutBtn' onClick={logoutHandler}>
                        Logout
                    </button>
                </li>
                </>
            )
        }
        else
        {
            return (
                <>
                    <li><Link to='/register'>Register</Link></li>
                    <li><Link to='/login'>Login</Link></li>
                </>
            )
        }
    }

    return (
        <div className='profile-bar'>
            <ul className={user.loggedIn ? 'ul-logged-in' : ''}>
                {showRegisterAndLogin()}
            </ul>
            {showProfileMenu()}
        </div>
    )
}

export default ProfileBar