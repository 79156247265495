import React, {createContext, useContext, useReducer, useEffect} from 'react';
import { loginUser, logoutUser, authenticateUser } from '../services/authService'
import UserReducer from '../reducers/UserReducer';

//Create Context and export a function to get it
const AuthContext = createContext();
export const useAuth = () => {
    return useContext(AuthContext)
}

//Provider Component to provide AuthContext which contains reducer state and dispatch functions
export const AuthProvider = ({children}) => {

    // if session exists, login user
    useEffect(() => {
        const getExistingSession = async () => {
            const authRes = await authenticateUser()
            if ( authRes )
            {
                //console.log('Auth Provider UseEffect email:', authRes.email)
                setExistingUser(authRes.data.email, authRes.data.role)
            }
            else
            {
                // console.log('Auth Provider UseEffect no user found:', err)
                setNoExistingUser()
            }
        }
  
      getExistingSession()
    }, []);

    //Initial State
    const initialState = {
        email: '',
        role: '',
        loggedIn: null
    }

    const [user, dispatch] = useReducer(UserReducer, initialState);

    //Actions
    const setNoExistingUser = async () => {
        dispatch({
            type: 'LOGOFF_USER'
        })
    }
    const setExistingUser = async (email, role) => {
        dispatch({
            type: 'LOGIN_USER',
            payload: {
                email: email,
                role: role
            }
        })
    }

    const logIn = async ({email, password}) => {
        
        // make an axios post to login route which will return our logged in email for success 
        // or an error status code for failure
        const loginRes = await loginUser({email, password})

        //console.log('logIn dispatch loginRes:', loginRes)
        if ( loginRes.data.error )
        {
            return loginRes
        }

        dispatch({
            type: 'LOGIN_USER',
            payload: {
                email: loginRes.data.email,
                role: loginRes.data.role
            }
        });
    }

    const logOff = async () => {
        
        // make an axios post to logout route which will clear backend session and clear cookies in frontend
        if ( user.loggedIn )
        {
            logoutUser()

            dispatch({
                type: 'LOGOFF_USER'
            });

            localStorage.clear();
        }
    }

    return(
        <AuthContext.Provider value={{
            user,
            logIn,
            logOff,
        }}>
            {children}
        </AuthContext.Provider>
    )
}
